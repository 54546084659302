<template>
  <div class="field">
    <span class="p-input-icon-right" :class="{ 'p-float-label':float, 'w-full': full }">
      <input-text :id="id" :type="showPass ? 'text' : 'password'" v-model="value" :class="{ 'p-invalid': error?.length > 0, 'w-full': full }" :required="required" />
      <label :for="id">{{ label }}</label>
      <i class="pi" :class="showPass ? 'pi-eye-slash' : 'pi-eye'" @click="showPass = !showPass"></i>
    </span>
    <small v-if="error.length > 0" class="p-error">{{ error }}</small>
  </div>
</template>

<style scoped>
.field small.p-error {
  display: block;
}
</style>

<script>
import { ref, computed, watchEffect } from "vue";

export default {
  props: {
    modelValue: [String, Number],
    name: String,
    label: String,
    float: {
      type: Boolean,
      default: () => false,
    },
    full: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => true,
    },
    error: String,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      }
    });

    const id = ref(new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16));
    watchEffect(() => {
      if (!props.name) return;
      id.value = props.name;
    });

    const showPass = ref(false);

    return { value, id, showPass };
  }
}
</script>