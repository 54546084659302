<template>
  <div class="field">
    <span :class="{ 'p-float-label':float, 'p-input-icon-right':icon, 'w-full': full }">
      <calendar :id="id" v-model="value" :class="{ 'p-invalid': error?.length > 0, 'w-full': full }" :date-format="format" :show-icon="icon" />
      <label :for="id">{{ label }}</label>
    </span>
    <small v-if="error?.length > 0" class="p-error">{{ error }}</small>
  </div>
</template>

<style scoped>
.field small.p-error {
  display: block;
}
</style>

<script>
import { ref, computed, watchEffect } from "vue";

export default {
  props: {
    modelValue: [String, Number],
    label: String,
    format: String,
    float: {
      type: Boolean,
      default: () => false,
    },
    full: {
      type: Boolean,
      default: () => false,
    },
    icon: {
      type: Boolean,
      default: () => false,
    },
    error: String,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      }
    });

    const id = ref(new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16));
    watchEffect(() => {
      if (!props.name) return;
      id.value = props.name;
    });

    return { value, id };
  }
}
</script>