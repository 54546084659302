import { ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";

export async function getMe() {
    return (await axios.get("/api/me")).data;
}

export async function getAdmin() {
    return (await axios.get("/api/admin")).data;
}

export function useLogout() {
    const router = useRouter();

    async function logout() {
      await axios.post("/api/logout");
      router.push("/login");
    }

    return { logout };
}

export function useClientLabels() {
    const store = useStore();
    const labels = ref([]);

    const ids = computed(() => {
        return labels.value.filter(l => l.type == "id");
    });

    const attrs = computed(() => {
        return labels.value.filter(l => l.type == "attr");
    });
  
    async function getClientLabels() {
        return (await axios.get("/api/labels/all")).data;
    }
    
    function getAttr(attrs, label) {
        return attrs.filter(a => a.client_label_id == label.id)[0];
    }
  
    watchEffect(async() => {
        if (!store.getters.isLogin) return;
        labels.value = (await getClientLabels())?.labels;
    });

    return { labels, ids, attrs, getClientLabels, getAttr };
}

export function useSubject() {
    const subject = ref({});
    const delSubject = ref({});
    const subjectDialog = ref(false);
    const confirmDelete = ref(false);
    const { ids, attrs, getAttr } = useClientLabels();

    function createSubject() {
      subject.value = {};
      subjectDialog.value = true;
    }

    function editSubject(s) {
      subject.value = {...s};
      for (let id of ids.value) {
        subject.value["id-"+id.id] = getAttr(s.attrs, id)?.value;
      }
      for (let attr of attrs.value) {
        subject.value["attr-"+attr.id] = getAttr(s.attrs, attr)?.value;
      }
      subjectDialog.value = true;
    }

    function removeSubject(s) {
        delSubject.value = s;
        confirmDelete.value = true;
    }

    function playSubject(s) {
        let audio = new Audio(`/api/subjects/${s.id}/voice`);
        audio.play();
        audio.addEventListener('ended', () => {
          s.playing = false;
        });
  
        s.playing = true;
        s.pause = () => {
          audio.pause();
          s.playing = false;
        };
    }
  
    return { subject, delSubject, subjectDialog, confirmDelete, createSubject, editSubject, removeSubject, playSubject };
}

export async function getSubject(id) {
    return (await axios.get(`/api/subjects/${id}`)).data;
}

export async function getUsers(params) {
    return (await axios.get("/api/users/", { params })).data;
}

export async function getSubjects(params) {
    return (await axios.get("/api/subjects/", { params })).data;
}

export async function getAdmins(params) {
    return (await axios.get("/api/admins/", { params })).data;
}

export async function getClientLabels(params) {
    return (await axios.get("/api/labels/", { params })).data;
}

export async function postUser(user) {
    if (user.id) {
        return (await axios.post(`/api/users/${user.id}`, user)).data;
    } else {
        return (await axios.post("/api/users/", user)).data;
    }
}

export async function postSubject(params, id) {
    if (id) {
        return (await axios.post(`/api/subjects/${id}`, params)).data;
    } else {
        return (await axios.post("/api/subjects/", params)).data;
    }
}

export async function postSubjectVoice(params, id) {
    return (await axios.post(`/api/subjects/${id}/voice`, params)).data;
}

export async function postAdmin(admin) {
    if (admin.id) {
        return (await axios.post(`/api/admins/${admin.id}`, admin)).data;
    } else {
        return (await axios.post("/api/admins/", admin)).data;
    }
}

export async function postClientLabel(label) {
    if (label.id) {
        return (await axios.post(`/api/labels/${label.id}`, label)).data;
    } else {
        return (await axios.post("/api/labels/", label)).data;
    }
}

export async function uploadUserCSV(file) {
    const params = new FormData();
    params.append("file", file);

    return (await axios.post("/api/users/upload/csv", params)).data;
}

export async function deleteUser(user_id) {
    return (await axios.delete(`/api/users/${user_id}`)).data;
}

export async function deleteSubject(subject_id) {
    return (await axios.delete(`/api/subjects/${subject_id}`)).data;
}

export async function deleteAdmin(admin_id) {
    return (await axios.delete(`/api/admins/${admin_id}`)).data;
}

export async function deleteClientLabel(label_id) {
    return (await axios.delete(`/api/labels/${label_id}`)).data;
}
