<template>
  <div class="flex align-items-center justify-content-center topbar">
    <h1><router-link to="/">ACOGNITION</router-link></h1>
    <div v-if="store.getters.me?.user || store.getters.admin?.admin" class="logout">
      <pv-button icon="pi pi-user" class=" p-button-text p-button-plain" :label="store.getters.me ? store.getters.me.user.login_id : store.getters.admin.admin.email"  disabled="disabled" />
      <pv-button icon="pi pi-power-off" class=" p-button-secondary" iconPos="right" @click="logout" />
    </div>
  </div>
  <div class="main">
    <router-view />
  </div>
  <pv-toast />
</template>

<style>
body {
  margin: 0;
}
h1 {
  margin: 1rem 0;
}
h1 a {
  text-decoration: none;
  color: black;
}
.logout {
  position: absolute;
  right: 0.5rem;
}
.main {
  height: calc(100vh - 81px);
  padding: 8px;
}
h2 {
  margin-top: 0;
}
form {
  padding-top: 0.5rem;
}
input[type="number"] {
  text-align: right;
}
.flex {
  flex-wrap: wrap;
  align-items: baseline;
}
.main > .grid  {
  align-items: start;
}
.input-label {
  padding-top: 10px!important;
}
.p-float-label {
  margin-top: 1rem;
}
.p-speeddial.p-button-raised > .p-button-rounded {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
              0 2px 2px 0 rgb(0 0 0 / 14%),
              0 1px 5px 0 rgb(0 0 0 / 12%);
}
.p-datatable-tbody td:last-child {
  text-align: right!important;
}
::v-deep(.p-dialog-content) {
  padding-top: 0.25rem;
}
::v-deep(.p-column-header-content) {
  position: relative;
}
::v-deep(.p-column-header-content > .p-sortable-column-icon) {
  position: absolute;
  left: -27px;
}
</style>

<script>
import axios from "axios";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";

export default {
  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    axios.interceptors.response.use((res) => res, (err) => {
      toast.add({ severity: "error", summary: "通信エラーが発生しました", life: 3000 });
      return Promise.reject(err);
    });

    async function logout() {
      if (store.state.me) {
        await axios.post("/api/logout");
        store.dispatch("setMe", null);
        router.push("/login");
      } else {
        await axios.post("/api/admin/logout");
        store.dispatch("setAdmin", null);
        router.push("/admin/login");
      }
    }

    return { logout, toast, store };
  },
}
</script>