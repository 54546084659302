import axios from "axios";

export async function refreshCSRFToken() {
    try {
        let r = await axios.get("/api/csrf_token");
        axios.defaults.headers.common = {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": r.data.token,
        };
    } catch(e) {
        console.log(e);
    }
}
