import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  state() {
    return {
      error: null,
      success: null,
      loginToken: null,
      me: null,
      admin: null,
    };
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    setSuccess(state, success) {
      state.success = success;
    },
    setLoginToken(state, token) {
      state.loginToken = token;
    },
    setMe(state, me) {
      if (!me || me.error) {
        state.me = null;
      } else {
        state.me = me;
      }
    },
    setAdmin(state, admin) {
      if (!admin || admin.error) {
        state.admin = null;
      } else {
        state.admin = admin;
      }
    },
  },
  actions: {
    setError({ commit }, message) {
      commit("setError", message);
    },
    setSuccess({ commit }, success) {
      commit("setSuccess", success);
    },
    setLoginToken({ commit }, token) {
      commit("setLoginToken", token);
    },
    setMe({ commit }, me) {
      commit("setMe", me);
    },
    setAdmin({ commit }, admin) {
      commit("setAdmin", admin);
    },
  },
  getters: {
    errorMessage: state => state.error,
    successMessage: state => state.success,
    me: state => state.me,
    admin: state => state.admin,
    isLogin: state => state.me || state.admin,
  },
  plugins: [createPersistedState()],
});

export default store;
