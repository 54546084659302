import "@/assets/common.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dayjs from "dayjs";
import VueCropper from "vue-cropperjs";
import 'cropperjs/dist/cropper.css';
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ja from "./prime-vue-ja";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(PrimeVue, { locale: ja });
app.use(ToastService);

dayjs.locale('ja');

app.use({
  install: (localApp) => {
    localApp.config.globalProperties.dayjs = dayjs;
  }
})

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Card from 'primevue/card';
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import PvCalendar from "@/components/PvCalendar.vue";
import PvInput from "@/components/PvInput.vue";
import PvPassword from "@/components/PvPassword.vue";
import PvRadio from "@/components/PvRadio.vue";
import PvCheckbox from "@/components/PvCheckbox.vue";
import Panel from "primevue/panel";
import SpeedDial from "primevue/speeddial";
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import InlineMessage from "primevue/inlinemessage";
import ProgressSpinner from 'primevue/progressspinner';

app.component("pv-button", Button);
app.component("calendar", Calendar);
app.component("pv-card", Card);
app.component("pv-column", Column);
app.component("pv-data-table", DataTable);
app.component("pv-dialog", Dialog);
app.component("pv-divider", Divider);
app.component("pv-dropdown", Dropdown);
app.component("input-text", InputText);
app.component("pv-calendar", PvCalendar);
app.component("pv-input", PvInput);
app.component("pv-password", PvPassword);
app.component("pv-radio", PvRadio);
app.component("pv-checkbox", PvCheckbox);
app.component("pv-panel", Panel);
app.component("pv-speed-dial", SpeedDial);
app.component("pv-toast", Toast);
app.component("pv-inline-message", InlineMessage);
app.component("pv-spinner", ProgressSpinner);

app.directive('tooltip', Tooltip);

import axios from "axios";
axios.interceptors.request.use((req) => {
  req.headers["x-host"] = location.host;
  return req;
});

if (!store.state.loginToken) {
  store.dispatch("setLoginToken", btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(24)))).substring(0,24));
}

import { refreshCSRFToken } from "@/common/csrf.js";
refreshCSRFToken().then();

import { getMe, getAdmin } from "@/common/api.js";

router.beforeEach(async (to, from, next) => {
  await refreshCSRFToken();
  try {
    let r = await axios.get('/api/');
    if (window.ScoreCodeRevision && r.data.revision != window.ScoreCodeRevision) {
      let rev = localStorage.getItem("ScoreCodeRevision");
      if (!rev || rev != r.data.revision) {
        localStorage.setItem("ScoreCodeRevision", r.data.revision);
        store.dispatch("setSuccess", "アプリが更新されたのでリロードを実施します");
        location.reload(true);
      }
    }
  } catch {
    console.log('invalid domain');
  }
  if (to.path.indexOf('/login') == -1 && to.path.indexOf('/password/') == -1) {
    store.dispatch("setMe", await getMe());
    store.dispatch("setAdmin", await getAdmin());
    if (!store.state.me && !store.state.admin) {
      next('/login');
      return;
    }
    if (to.path.indexOf('/staff/') != 0 && store.state.me?.user.role == "staff") {
      next('/staff/');
      return
    }
  } else {
    if (store.state.me || store.state.admin) {
      next('/');
    }
  }
  next();
});

app.component("vue-cropper", VueCropper)

import sanitizeHTML from "sanitize-html";
import AutoLinker from "autolinker";

app.mixin({
  methods: {
    sanitize(text) {
      return AutoLinker.link(sanitizeHTML((text || "").replace(/\n/g, "<br />")), {
        replaceFn: (match) => {
          const tag = match.buildTag();
          tag.setInnerHTML(match.getMatchedText());
          return tag;
        }
      });
    },
    submit() {
      document.getElementById('submit').click();
    },
    even(array) {
      return array.filter((_, i) => i % 2 == 0);
    },
    odd(array) {
      return array.filter((_, i) => i % 2 != 0);
    }
  }
});

app.mount("#app");
