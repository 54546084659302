<template>
  <div class="field-radiobutton">
    <radio-button :id="id" :value="value" v-model="model" />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from "vue";
import RadioButton from "primevue/radiobutton";

export default {
  props: {
    modelValue: [String, Number],
    name: String,
    value: [String, Number],
    label: String,
  },
  components: {
    RadioButton,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      }
    });

    const id = ref(new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16));
    watchEffect(() => {
      if (!props.name) return;
      id.value = props.name;
    });

    return { model, id };
  }
}
</script>